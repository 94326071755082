import { Swiper, SwiperSlide } from "swiper/react";
import { regionImages, regionSplashImg } from "../../../assets"
import Avatar from "./Avatar";
import { FreeMode, Pagination } from "swiper";

const RegionSide = ({ champRegion, champ, mp }) => {
    return (
        <>
            <>
                <div className="region-container">
                    <img className="region-emblem" alt="region-emblem" src={(regionImages[`${champRegion.toLowerCase()}_emblem.png`])} />
                    <div className="region-show"
                        style={{ '--regionSplash': `url(${regionSplashImg[champRegion.replace(/\s/g, "").toLowerCase() + '_splash.jpg']})` }}>
                    </div>
                </div>

                <div className="region-title">
                    <div className="region-name">
                        <h5>
                            <span>Region</span>
                        </h5>
                        <h6>
                            <span>{champRegion}</span>
                        </h6>
                    </div>

                    <div className="region-faction" style={{ display: "inline" }}>
                        <img src={(regionImages[`${champRegion.toLowerCase()}.png`])} alt={champRegion} />
                    </div>
                </div>
            </>

            {/* if related champions is zero in the case of Amumu */}
            {champ['related-champions']?.length > 0 && (
                <div className="bio-related">
                    <div className="related-title">Related Champions</div>
                    <Swiper className="related-container"
                        slidesPerView={"auto"}
                        navigation
                        modules={[FreeMode, Pagination]}
                        freeMode={true}
                    >
                        {champ['related-champions']?.map((relatedChamp, index) => (
                            <SwiperSlide key={index}>
                                <Avatar champ={relatedChamp} mapURLBE_GameData={mp} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </>
    )
}

export default RegionSide;