import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { rareIcon, epicIcon, legendaryIcon, mythicIcon, ultimateIcon } from "../../../assets";
import { useChampListContext } from "../../../context/ChampListContext"

const rarityIcons = { rare: rareIcon, epic: epicIcon, legendary: legendaryIcon, mythic: mythicIcon, ultimate: ultimateIcon };

const SkinTab = ({ activeTab, switchTab, mapBEPath, CDNSole, onSelect, isPreloaded, isCenter, setIsCenter }) => {
    const [swiper, setSwiper] = useState(null);
    const params = { onSwiper: setSwiper };  // this will give us a reference to the Swiper instance
    const { selectedSplash, setSelectedSplash, sanitizeText } = useChampListContext()

    useEffect(() => {
        if (swiper !== null && swiper.activeIndex !== 0) {swiper.slideTo(0);}
    }, [CDNSole]);

    const handleSelect = (index) => () => onSelect(index);

    const toggleCenterSplash = (e) => {
        e.stopPropagation();
        setIsCenter(!isCenter);
    }

    const toggleView = (e) => {
        e.stopPropagation();
        focusToHide();
        setTimeout(() => {
            unFocusToShow()
        }, 2000);
    }
    
    const focusToHide = () => {
        const [abilityTab, champTab, skinTab, transbox] = ['skill-show', 'champ-detail', 'skin-show-toggle', "transbox"].map(id => document.getElementById(id));
        [abilityTab, champTab, skinTab, transbox].forEach(tab => tab.classList.remove('focusOut'));
        [abilityTab, champTab, skinTab, transbox].forEach(tab => tab.classList.toggle('focusOn'));
    }
    
    const unFocusToShow = () => {
        const [abilityTab, champTab, skinTab, transbox] = ['skill-show', 'champ-detail', 'skin-show-toggle', "transbox"].map(id => document.getElementById(id));
        [abilityTab, champTab, skinTab, transbox].forEach(tab => tab.classList.remove('focusOn'));
        [abilityTab, champTab, skinTab, transbox].forEach(tab => tab.classList.toggle('focusOut'));
    }; 
    
    return (
        <aside id="skin-show-toggle" className={`skin-component ${activeTab === 'skin-show' ? 'active' : ''}`}>
            <span className="skin-dim " onClick={() => switchTab('skin-show')}>
                {CDNSole.skins && 
                <>
                    {!CDNSole.skins[selectedSplash].isBase &&
                        <div className="skin-info">
                            <div>
                                {CDNSole.skins[selectedSplash].rarity.replace(/^./, '').toLowerCase() !== "norarity" &&
                                    <img src={rarityIcons[CDNSole.skins[selectedSplash].rarity.replace(/^./, '').toLowerCase()]} alt={'skin-info'}
                                        className="skin-icon" />
                                }
                                <h3 className="">{CDNSole.skins[selectedSplash].name}</h3>
                            </div>
                            <p>{CDNSole.skins[selectedSplash].description}</p>
                            {CDNSole.skins[selectedSplash].featuresText &&
                                <span className="skin-feature">
                                    {sanitizeText(CDNSole.skins[selectedSplash].featuresText)}
                                </span>
                            }
                        </div>
                    }
                        
                    <div className="skin-addon">
                        <button className="addon-hide" onClick={toggleView}>
                            <svg viewBox="40.3065 73.3216 453.0418 290.6969" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                                <path d="M 248.500 74.113 C 195.519 79.072, 133.630 113.021, 78.975 167.106 C 63.608 182.312, 45.781 202.549, 43.068 207.866 C 36.190 221.349, 41.333 231.990, 67.914 259.268 C 143.157 336.486, 220.088 372.207, 290.108 362.441 C 326.114 357.419, 358.211 344.218, 396.500 318.684 C 415.620 305.933, 441.562 284.319, 461.360 264.646 C 485.278 240.878, 493.061 229.494, 493.331 217.877 C 493.451 212.754, 493.049 211.633, 488.817 205.268 C 478.266 189.400, 462.881 173.334, 433 146.981 C 372.184 93.343, 309.124 68.438, 248.500 74.113 M 246.500 101.143 C 200.999 106.931, 149.428 136.633, 97.623 186.889 C 87.209 196.992, 77.620 207.901, 75.427 212.141 C 72.914 217, 73.351 222.456, 76.632 227.195 C 81.327 233.977, 108.369 261.289, 120.355 271.354 C 139.648 287.556, 163.743 304.409, 182 314.472 C 200.880 324.877, 223.414 332.525, 244.168 335.569 C 260.766 338.004, 287.611 337.129, 302 333.684 C 336.662 325.386, 370.982 306.774, 407.544 276.446 C 435.658 253.127, 453.390 235.035, 458.630 224.323 L 461.255 218.958 458.878 214.426 C 451.715 200.774, 439.284 187.525, 412.307 164.789 C 366.304 126.018, 328.228 106.857, 285.678 101.065 C 276.564 99.824, 256.556 99.864, 246.500 101.143 M 256 139.597 C 232.355 143.962, 215.540 153.545, 203.532 169.501 C 191.989 184.837, 187.548 198.582, 187.538 219 C 187.530 234.693, 188.849 240.529, 195.560 254.500 C 204.952 274.053, 221.546 287.938, 243.500 294.614 C 251.388 297.012, 254.229 297.359, 266.500 297.423 C 278.157 297.483, 281.923 297.109, 289 295.187 C 304.354 291.017, 321.219 280.050, 330.554 268.164 C 351.040 242.084, 352.243 201.194, 333.361 172.791 C 324.268 159.113, 306.067 146.490, 288.774 141.869 C 281.716 139.983, 261.502 138.582, 256 139.597" ></path>
                            </svg>
                        </button>

                        {isPreloaded && <button className={`addon-center ${isCenter ? "centered" : ""} `} onClick={toggleCenterSplash}>
                            {/* Toggle Center: {isCenter ? 'Centered' :'Default'}  */}
                            <svg width="100%" height="100%" viewBox="0 0 10 12" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="3" r="3"></circle>
                                <path d="M10,12A5,5,0,0,0,5,8a5.09,5.09,0,0,0-5,4Z"></path>
                            </svg>
                        </button>}
                    </div>
                </>
                }
            </span>

            <span className={`skin-toggler ${activeTab === 'skin-show' ? 'active' : activeTab === null ? "" : 'hide'}`} onClick={() => switchTab('skin-show')}>
                Skins
            </span>

            <div className={`skin-show ${activeTab === 'skin-show' ? 'active' : ''}`} id="skin-show">
                <Swiper className="skin-swiper"
                    direction={"vertical"}
                    speed={500}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    slideToClickedSlide={true}
                    pagination={{ clickable: true }}
                    onRealIndexChange={(e) => onSelect(e.activeIndex)}
                    modules={[Pagination]}
                    {...params}
                >
                    {CDNSole.skins?.map((skin, index) => (
                        <SwiperSlide className="skin-slide" key={index}>
                            <div onClick={handleSelect(index)}> {/* onClick={() => setSkin(index) */}
                                <div className="skinView">
                                    <img src={mapBEPath(skin.uncenteredSplashPath)} width="409" height="288" alt={skin.name} />
                                    <span key={skin.id}>{skin.name}</span>
                                </div>
                                {CDNSole.skins && CDNSole.skins.length > index && CDNSole.skins[index].rarity && CDNSole.skins[index].rarity.replace(/^./, '').toLowerCase() !== "norarity" &&
                                    <img src={rarityIcons[CDNSole.skins[index].rarity.replace(/^./, '').toLowerCase()]} alt={skin.name} className="skinRarity" />
                                }
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </aside>
    );
}
export default SkinTab;