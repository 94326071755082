/* const newRegion = [] -- pang get lang para sa regionapi lagay sa HomeJS 
   const pick = Object.entries(aw)
   console.log(pick)
   for (let [id, obj] of pick){
       console.log(`${id} ${obj}`)

       let noisce = (({ id, name, key, version }) => ({ id, name, key, version }))(obj);
       newRegion.push(noisce) 
   }

   console.log(newRegion)


          
    regionAPI.forEach(function (e) {
       e.region = 'Runeterra'
    })


   console.log(regionAPI)
*/


export const regionAPI = [
    {
        "id": "Aatrox",
        "name": "Aatrox",
        "key": "266",
        "region": "Runeterra",
        "colorTheme": '#fe5a49',
    },
    {
        "id": "Ahri",
        "name": "Ahri",
        "key": "103",
        "region": "Ionia",
        "colorTheme": '#243ce3',
    },
    {
        "id": "Akali",
        "name": "Akali",
        "key": "84",
        "region": "Ionia",
        "colorTheme": '#81e384',
    },
    {
        "id": "Akshan",
        "name": "Akshan",
        "key": "166",
        "region": "Shurima",
        "colorTheme": '#eadb5d',
    },
    {
        "id": "Alistar",
        "name": "Alistar",
        "key": "12",
        "region": "Runeterra",
        "colorTheme": '#9078a8'
    },
    {
        "id": "Amumu",
        "name": "Amumu",
        "key": "32",
        "region": "Shurima",
        "colorTheme": "#228848"
    },
    {
        "id": "Anivia",
        "name": "Anivia",
        "key": "34",
        "region": "Freljord",
        "colorTheme": "#90def5"
    },
    {
        "id": "Annie",
        "name": "Annie",
        "key": "1",
        "region": "Runeterra",
        "colorTheme": "#ef931e"
    },
    {
        "id": "Aphelios",
        "name": "Aphelios",
        "key": "523",
        "region": "Targon",
        "colorTheme": "#47288c"
    },
    {
        "id": "Ashe",
        "name": "Ashe",
        "key": "22",
        "region": "Freljord",
        "colorTheme": "#145dd4"
    },
    {
        "id": "AurelionSol",
        "name": "Aurelion Sol",
        "key": "136",
        "region": "Runeterra",
        "colorTheme": "#2c53c3"
    },
    {
        "id": "Azir",
        "name": "Azir",
        "key": "268",
        "region": "Shurima",
        "colorTheme": "#daa844"
    },
    {
        "id": "Bard",
        "name": "Bard",
        "key": "432",
        "region": "Runeterra",
        "colorTheme": "#f4c47c"
    },
    {
        "id": "Belveth",
        "name": "Bel'Veth",
        "key": "200",
        "region": "The Void",
        "colorTheme": "#b846aa"
    },
    {
        "id": "Blitzcrank",
        "name": "Blitzcrank",
        "key": "53",
        "region": "Zaun",
        "colorTheme": "#fec85d"
    },
    {
        "id": "Brand",
        "name": "Brand",
        "key": "63",
        "region": "Runeterra",
        "colorTheme": "#bf5a1e"
    },
    {
        "id": "Braum",
        "name": "Braum",
        "key": "201",
        "region": "Freljord",
        "colorTheme": "#50b8e3"
    },
    {
        "id": "Briar",
        "name": "Briar",
        "key": "233",
        "region": "Noxus",
        "colorTheme": "#50b8e3"
    },
    {
        "id": "Caitlyn",
        "name": "Caitlyn",
        "key": "51",
        "region": "Piltover",
        "colorTheme": "#1f1551"
    },
    {
        "id": "Camille",
        "name": "Camille",
        "key": "164",
        "region": "Piltover",
        "colorTheme": "#d5d0cd"
    },
    {
        "id": "Cassiopeia",
        "name": "Cassiopeia",
        "key": "69",
        "region": "Noxus",
        "colorTheme": "#69be44"
    },
    {
        "id": "Chogath",
        "name": "Cho'Gath",
        "key": "31",
        "region": "The Void",
        "colorTheme": "#da4596"
    },
    {
        "id": "Corki",
        "name": "Corki",
        "key": "42",
        "region": "Bandle City",
        "colorTheme": "#5d729a"
    },
    {
        "id": "Darius",
        "name": "Darius",
        "key": "122",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Diana",
        "name": "Diana",
        "key": "131",
        "region": "Targon",
        "colorTheme": "#d4d8ec"
    },
    {
        "id": "Draven",
        "name": "Draven",
        "key": "119",
        "region": "Noxus",
        "colorTheme": "#ffcead"
    },
    {
        "id": "DrMundo",
        "name": "Dr. Mundo",
        "key": "36",
        "region": "Zaun",
        "colorTheme": "#a74cc5"
    },
    {
        "id": "Ekko",
        "name": "Ekko",
        "key": "245",
        "region": "Zaun",
        "colorTheme": "#5ab6c1"
    },
    {
        "id": "Elise",
        "name": "Elise",
        "key": "60",
        "region": "Shadow Isles",
        "colorTheme": "#9c082f"
    },
    {
        "id": "Evelynn",
        "name": "Evelynn",
        "key": "28",
        "region": "Runeterra",
        "colorTheme": "#b130a0"
    },
    {
        "id": "Ezreal",
        "name": "Ezreal",
        "key": "81",
        "region": "Piltover",
        "colorTheme": "#fffa59"
    },
    {
        "id": "Fiddlesticks",
        "name": "Fiddlesticks",
        "key": "9",
        "region": "Runeterra",
        "colorTheme": "#75f9b0"
    },
    {
        "id": "Fiora",
        "name": "Fiora",
        "key": "114",
        "region": "Demacia",
        "colorTheme": "#bb3d73"
    },
    {
        "id": "Fizz",
        "name": "Fizz",
        "key": "105",
        "region": "Runeterra",
        "colorTheme": "#1691cf"
    },
    {
        "id": "Galio",
        "name": "Galio",
        "key": "3",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Gangplank",
        "name": "Gangplank",
        "key": "41",
        "region": "Bilgewater",
        "colorTheme": "#be1614"
    },
    {
        "id": "Garen",
        "name": "Garen",
        "key": "86",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Gnar",
        "name": "Gnar",
        "key": "150",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Gragas",
        "name": "Gragas",
        "key": "79",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Graves",
        "name": "Graves",
        "key": "104",
        "region": "Bilgewater",
        "colorTheme": "#be1614"
    },
    {
        "id": "Gwen",
        "name": "Gwen",
        "key": "887",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "Hecarim",
        "name": "Hecarim",
        "key": "120",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "Heimerdinger",
        "name": "Heimerdinger",
        "key": "74",
        "region": "Piltover",
        "colorTheme": "#be1614"
    },
    {
        "id": "Hwei",
        "name": "Hwei",
        "key": "910",
        "region": "Ionia",
        "colorTheme": "#EF7C46"
    },
    {
        "id": "Illaoi",
        "name": "Illaoi",
        "key": "420",
        "region": "Bilgewater",
        "colorTheme": "#be1614"
    },
    {
        "id": "Irelia",
        "name": "Irelia",
        "key": "39",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Ivern",
        "name": "Ivern",
        "key": "427",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Janna",
        "name": "Janna",
        "key": "40",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "JarvanIV",
        "name": "Jarvan IV",
        "key": "59",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Jax",
        "name": "Jax",
        "key": "24",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Jayce",
        "name": "Jayce",
        "key": "126",
        "region": "Piltover",
        "colorTheme": "#be1614"
    },
    {
        "id": "Jhin",
        "name": "Jhin",
        "key": "202",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Jinx",
        "name": "Jinx",
        "key": "222",
        "region": "Zaun",
        "colorTheme": "#be1614",
        "colorTheme": "#be1614"
    },
    {
        "id": "Kaisa",
        "name": "Kai'Sa",
        "key": "145",
        "region": "The Void",
        "colorTheme": "#be1614"
    },
    {
        "id": "Kalista",
        "name": "Kalista",
        "key": "429",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "Karma",
        "name": "Karma",
        "key": "43",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Karthus",
        "name": "Karthus",
        "key": "30",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "Kassadin",
        "name": "Kassadin",
        "key": "38",
        "region": "The Void",
        "colorTheme": "#be1614"
    },
    {
        "id": "Katarina",
        "name": "Katarina",
        "key": "55",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Kayle",
        "name": "Kayle",
        "key": "10",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Kayn",
        "name": "Kayn",
        "key": "141",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Kennen",
        "name": "Kennen",
        "key": "85",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Khazix",
        "name": "Kha'Zix",
        "key": "121",
        "region": "The Void",
        "colorTheme": "#be1614"
    },
    {
        "id": "Kindred",
        "name": "Kindred",
        "key": "203",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Kled",
        "name": "Kled",
        "key": "240",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "KogMaw",
        "name": "Kog'Maw",
        "key": "96",
        "region": "The Void",
        "colorTheme": "#be1614"
    },
    {
        "id": "KSante",
        "name": "K'Sante",
        "key": "897",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "Leblanc",
        "name": "LeBlanc",
        "key": "7",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "LeeSin",
        "name": "Lee Sin",
        "key": "64",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Leona",
        "name": "Leona",
        "key": "89",
        "region": "Targon",
        "colorTheme": "#be1614"
    },
    {
        "id": "Lillia",
        "name": "Lillia",
        "key": "876",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Lissandra",
        "name": "Lissandra",
        "key": "127",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Lucian",
        "name": "Lucian",
        "key": "236",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Lulu",
        "name": "Lulu",
        "key": "117",
        "region": "Bandle City",
        "colorTheme": "#be1614"
    },
    {
        "id": "Lux",
        "name": "Lux",
        "key": "99",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Malphite",
        "name": "Malphite",
        "key": "54",
        "region": "Ixtal",
        "colorTheme": "#be1614"
    },
    {
        "id": "Malzahar",
        "name": "Malzahar",
        "key": "90",
        "region": "The Void",
        "colorTheme": "#be1614"
    },
    {
        "id": "Maokai",
        "name": "Maokai",
        "key": "57",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "MasterYi",
        "name": "Master Yi",
        "key": "11",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Milio",
        "name": "Milio",
        "key": "902",
        "region": "Ixtal",
        "colorTheme": "#be1614"
    },
    {
        "id": "MissFortune",
        "name": "Miss Fortune",
        "key": "21",
        "region": "Bilgewater",
        "colorTheme": "#be1614"
    },
    {
        "id": "MonkeyKing",
        "name": "Wukong",
        "key": "62",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Mordekaiser",
        "name": "Mordekaiser",
        "key": "82",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Morgana",
        "name": "Morgana",
        "key": "25",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Naafiri",
        "name": "Naafiri",
        "key": "950",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "Nami",
        "name": "Nami",
        "key": "267",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Nasus",
        "name": "Nasus",
        "key": "75",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "Nautilus",
        "name": "Nautilus",
        "key": "111",
        "region": "Bilgewater",
        "colorTheme": "#be1614"
    },
    {
        "id": "Neeko",
        "name": "Neeko",
        "key": "518",
        "region": "Ixtal",
        "colorTheme": "#be1614"
    },
    {
        "id": "Nidalee",
        "name": "Nidalee",
        "key": "76",
        "region": "Ixtal",
        "colorTheme": "#be1614"
    },
    {
        "id": "Nilah",
        "name": "Nilah",
        "key": "895",
        "region": "Bilgewater",
        "colorTheme": "#be1614"
    },
    {
        "id": "Nocturne",
        "name": "Nocturne",
        "key": "56",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Nunu",
        "name": "Nunu & Willump",
        "key": "20",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Olaf",
        "name": "Olaf",
        "key": "2",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Orianna",
        "name": "Orianna",
        "key": "61",
        "region": "Piltover",
        "colorTheme": "#be1614"
    },
    {
        "id": "Ornn",
        "name": "Ornn",
        "key": "516",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Pantheon",
        "name": "Pantheon",
        "key": "80",
        "region": "Targon",
        "colorTheme": "#be1614"
    },
    {
        "id": "Poppy",
        "name": "Poppy",
        "key": "78",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Pyke",
        "name": "Pyke",
        "key": "555",
        "region": "Bilgewater",
        "colorTheme": "#be1614"
    },
    {
        "id": "Qiyana",
        "name": "Qiyana",
        "key": "246",
        "region": "Ixtal",
        "colorTheme": "#be1614"
    },
    {
        "id": "Quinn",
        "name": "Quinn",
        "key": "133",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Rakan",
        "name": "Rakan",
        "key": "497",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Rammus",
        "name": "Rammus",
        "key": "33",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "RekSai",
        "name": "Rek'Sai",
        "key": "421",
        "region": "The Void",
        "colorTheme": "#be1614"
    },
    {
        "id": "Rell",
        "name": "Rell",
        "key": "526",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Renata",
        "name": "Renata Glasc",
        "key": "888",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Renekton",
        "name": "Renekton",
        "key": "58",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "Rengar",
        "name": "Rengar",
        "key": "107",
        "region": "Ixtal",
        "colorTheme": "#be1614"
    },
    {
        "id": "Riven",
        "name": "Riven",
        "key": "92",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Rumble",
        "name": "Rumble",
        "key": "68",
        "region": "Bandle City",
        "colorTheme": "#be1614"
    },
    {
        "id": "Ryze",
        "name": "Ryze",
        "key": "13",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Samira",
        "name": "Samira",
        "key": "360",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Sejuani",
        "name": "Sejuani",
        "key": "113",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Senna",
        "name": "Senna",
        "key": "235",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Seraphine",
        "name": "Seraphine",
        "key": "147",
        "region": "Piltover",
        "colorTheme": "#be1614"
    },
    {
        "id": "Sett",
        "name": "Sett",
        "key": "875",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Shaco",
        "name": "Shaco",
        "key": "35",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Shen",
        "name": "Shen",
        "key": "98",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Shyvana",
        "name": "Shyvana",
        "key": "102",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Singed",
        "name": "Singed",
        "key": "27",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Sion",
        "name": "Sion",
        "key": "14",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Sivir",
        "name": "Sivir",
        "key": "15",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "Skarner",
        "name": "Skarner",
        "key": "72",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "Smolder",
        "name": "Smolder",
        "key": "901",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Sona",
        "name": "Sona",
        "key": "37",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Soraka",
        "name": "Soraka",
        "key": "16",
        "region": "Targon",
        "colorTheme": "#be1614"
    },
    {
        "id": "Swain",
        "name": "Swain",
        "key": "50",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Sylas",
        "name": "Sylas",
        "key": "517",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Syndra",
        "name": "Syndra",
        "key": "134",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "TahmKench",
        "name": "Tahm Kench",
        "key": "223",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Taliyah",
        "name": "Taliyah",
        "key": "163",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "Talon",
        "name": "Talon",
        "key": "91",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Taric",
        "name": "Taric",
        "key": "44",
        "region": "Targon",
        "colorTheme": "#be1614"
    },
    {
        "id": "Teemo",
        "name": "Teemo",
        "key": "17",
        "region": "Bandle City",
        "colorTheme": "#be1614"
    },
    {
        "id": "Thresh",
        "name": "Thresh",
        "key": "412",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "Tristana",
        "name": "Tristana",
        "key": "18",
        "region": "Bandle City",
        "colorTheme": "#be1614"
    },
    {
        "id": "Trundle",
        "name": "Trundle",
        "key": "48",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Tryndamere",
        "name": "Tryndamere",
        "key": "23",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "TwistedFate",
        "name": "Twisted Fate",
        "key": "4",
        "region": "Bilgewater",
        "colorTheme": "#be1614"
    },
    {
        "id": "Twitch",
        "name": "Twitch",
        "key": "29",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Udyr",
        "name": "Udyr",
        "key": "77",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Urgot",
        "name": "Urgot",
        "key": "6",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Varus",
        "name": "Varus",
        "key": "110",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Vayne",
        "name": "Vayne",
        "key": "67",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Veigar",
        "name": "Veigar",
        "key": "45",
        "region": "Bandle City",
        "colorTheme": "#be1614"
    },
    {
        "id": "Velkoz",
        "name": "Vel'Koz",
        "key": "161",
        "region": "The Void",
        "colorTheme": "#be1614"
    },
    {
        "id": "Vex",
        "name": "Vex",
        "key": "711",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "Vi",
        "name": "Vi",
        "key": "254",
        "region": "Piltover",
        "colorTheme": "#be1614"
    },
    {
        "id": "Viego",
        "name": "Viego",
        "key": "234",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "Viktor",
        "name": "Viktor",
        "key": "112",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Vladimir",
        "name": "Vladimir",
        "key": "8",
        "region": "Noxus",
        "colorTheme": "#be1614"
    },
    {
        "id": "Volibear",
        "name": "Volibear",
        "key": "106",
        "region": "Freljord",
        "colorTheme": "#be1614"
    },
    {
        "id": "Warwick",
        "name": "Warwick",
        "key": "19",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Xayah",
        "name": "Xayah",
        "key": "498",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Xerath",
        "name": "Xerath",
        "key": "101",
        "region": "Shurima",
        "colorTheme": "#be1614"
    },
    {
        "id": "XinZhao",
        "name": "Xin Zhao",
        "key": "5",
        "region": "Demacia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Yasuo",
        "name": "Yasuo",
        "key": "157",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Yone",
        "name": "Yone",
        "key": "777",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Yorick",
        "name": "Yorick",
        "key": "83",
        "region": "Shadow Isles",
        "colorTheme": "#be1614"
    },
    {
        "id": "Yuumi",
        "name": "Yuumi",
        "key": "350",
        "region": "Bandle City",
        "colorTheme": "#be1614"
    },
    {
        "id": "Zac",
        "name": "Zac",
        "key": "154",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Zed",
        "name": "Zed",
        "key": "238",
        "region": "Ionia",
        "colorTheme": "#be1614"
    },
    {
        "id": "Zeri",
        "name": "Zeri",
        "key": "221",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Ziggs",
        "name": "Ziggs",
        "key": "115",
        "region": "Zaun",
        "colorTheme": "#be1614"
    },
    {
        "id": "Zilean",
        "name": "Zilean",
        "key": "26",
        "region": "Runeterra",
        "colorTheme": "#be1614"
    },
    {
        "id": "Zoe",
        "name": "Zoe",
        "key": "142",
        "region": "Targon",
        "colorTheme": "#be1614"
    },
    {
        "id": "Zyra",
        "name": "Zyra",
        "key": "143",
        "region": "Ixtal",
        "colorTheme": "#be1614"
    }
]