import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";

import { headerBorder } from '../assets'
import { regionAPI } from "../config/Region";
import { ddChampJSON, champSplash, cdnChampJSON, rawCDN, cdnSounds, setCurrentVersion, checkVersion, uniChampJSON } from "../config/RiotAPI";

//Components
import RegionSide from "../components/Card/Bio-Section/RegionSide";
import RoleFront from "../components/Card/Bio-Section/Role";
import CombatBack from "../components/Card/Bio-Section/CombatBack";
import Avatar from '../components/Card/Bio-Section/Avatar';

import AbilitiesTab from "../components/Card/Skill-Section/AbililitiesTab";
import SkinTab from "../components/Card/Skin-Section/SkinTab";
import CardNavigation from "../components/Card/CardNavigation";

import usePreconnect from '../utils/Preconnect';

import LoaderSpin from '../components/LoaderSpin';

import { useChampListContext } from "../context/ChampListContext"
    
const ChampSplash = ({champData, mapPath, selectedImg, selectedIndex}) => {
    const imgRef = useRef();
    useEffect(() => {
        if (imgRef.current) {
            imgRef.current.classList.remove('splash-in');
            void imgRef.current.offsetWidth; // Trigger reflow to restart animation
            imgRef.current.classList.add('splash-in');
        }
    }, [selectedImg]);

    return (
        champData && champData?.id && (
            <div className="champ-show" id='champBG' style={{backgroundImage: `url(${mapPath(champData.skins[selectedIndex].uncenteredSplashPath)})`,}}>
                <div className='champ-splash'>
                    <div className="champ-image" >
                        <img ref={imgRef} src={mapPath(selectedImg)} key={selectedImg} className="splash-in champLoad" alt={'ChampSplash'}/>
                    </div>
                </div>
            </div>
        )
    )

} 

const ChampDetail = () => {
    const { id } = useParams();

    const [DDChamp, setDDChamp] = useState({});
    const [CDNChamp, setCDNChamp] = useState({});
    const [UNIChamp, setUNIChamp] = useState({});

    const [isLoading, setIsLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(null);

    const { sanitizeText } = useChampListContext()

    const { selectedSplash, setSelectedSplash } = useChampListContext()
    const [isCenterSplash, setIsCenterSplash] = useState(false);
    const [imagesPreloaded, setImagesPreloaded] = useState(false)

    /* Community Dragon API */
    const tacticalInfo = CDNChamp.tacticalInfo;
    const playstyleInfo = CDNChamp.playstyleInfo;

    usePreconnect(["https://raw.communitydragon.org", "https://universe-meeps.leagueoflegends.com"]);
    
    const getChampData = async (champId) => {
        try {
            // Fetch data from the first source 
            //console.log(ddChampJSON() + `${champId}.json`)
            const ddChampDataResponse = await fetch(`${ddChampJSON()}${champId}.json`);
            const soloData = await ddChampDataResponse.json();
            const ddChampData = soloData.data[champId];

            regionAPI.forEach(namey => { //go through list of objects to find champ region from RegionAPI 
                if (namey.id === champId) {
                    ddChampData.region = namey.region;
                }
            }
            )
            console.log(`Data Dragon API: `, ddChampData)
            //console.log(Object.keys(ddChampData))

            // Fetch data from the second source
            const cdnChampResponse = await fetch(`${cdnChampJSON}${ddChampData.key}.json`);
            const cdnChampData = await cdnChampResponse.json();
            console.log(`Community Dragon API: `, cdnChampData)
            //console.log(Object.keys(cdnChampData))

            // Fetch data from the third source
            const uniChampResponse = await fetch(`${uniChampJSON}${ddChampData.id.toLowerCase()}/index.json`);
            const uniChampData = await uniChampResponse.json();
            console.log(`LoL Universe API: `, uniChampData)
            //console.log(Object.keys(uniChampData))

            // Update document title and set states or perform any other actions as needed
            document.title = `${ddChampData.name} | ${ddChampData.title}`;

            setDDChamp(ddChampData);
            setCDNChamp(cdnChampData);
            setUNIChamp(uniChampData);

        } catch (error) {
            console.error('Error fetching data:', error);
            return null; // or handle the error as needed
        }
    };

    const switchTab = (tab) => {
        setActiveTab(tab === activeTab ? null : tab);
        // if active tab is clicked again, close the active tab by reset to no active tabs
        // else swtich to requested tab
        if(tab === 'skin-show'){
            if ( CDNChamp.skins && !imagesPreloaded ) {
                console.log('Skin-Tab Detected: Preloading centered skin')
                // Preload images when isCenterSplash is true for the first time
                CDNChamp.skins.forEach(skin => {
                  const img = new Image();
                  img.src = mapBEGamePath(skin.splashPath);
                });
                setTimeout(() => {
                    setImagesPreloaded(true);
                }, 1500);
                
              }
        }
    }
    
    // Helper function to combat DRY (dont repeat yourself)
    const toggleClass = (selector, className) => {
        const element = document.querySelector(selector);
        element.classList.toggle(className);

        /*  simply does this 
            const bioToggler = document.querySelector('.bio-toggle');
            bioToggler.classList.toggle('show-top');
        */
    }

    const bioActive = () => {
        toggleClass('.bio-role', 'show-left');
        toggleClass('.bio-region', 'show-right');
        toggleClass('.bio-toggle', 'show-top');
    }

    const bioRole = () => {
        toggleClass('.bio-role', 'combat');
    };

    const audioPlay = (button, secondaryAudio = "default") => {
        let primaryAudio = button.firstElementChild;

        const playCallback = () => { button.classList.add("playing"); };

        const pauseCallback = () => { button.classList.remove("playing"); };

        const playAudio = (audio) => {
            if (audio instanceof HTMLAudioElement) {
                audio.play();
                audio.addEventListener("play", playCallback);
                audio.addEventListener("pause", pauseCallback);
            } else {
                console.error('audioChild is not an instance of HTMLAudioElement');
            }
        };

        if (secondaryAudio === "default") { 
            playAudio(primaryAudio);
        } else { 
            if (secondaryAudio instanceof HTMLAudioElement) {
                secondaryAudio.play();
                secondaryAudio.addEventListener("play", playCallback);
                setTimeout(() => {
                    if (primaryAudio.paused) { 
                        playAudio(primaryAudio);
                    }
                }, 500);
            } else {
                console.error('audioChild or audio2 is not an instance of HTMLAudioElement');
            }
        }
    };

    const AudioButton = ({ audioPaths, style, label }) => {
        return (
            <button onClick={e => audioPlay(e.target, e.target.children[1])} style={style}>
                {audioPaths &&
                    audioPaths.map((path, index) => <audio key={index} id={`audio${index}`} src={path} type="audio/ogg"></audio>
                )}
                <span>{label}</span>
            </button>
        );
    };

    // * mapBEGamePath fixes the url provided by the api to point to right data. 
    const mapBEGamePath = (urlPath) => {
        const lowercasedPath = urlPath.toLowerCase().split("/").slice(3).join("/");
        return `${rawCDN}/latest/plugins/rcp-be-lol-game-data/global/default/${lowercasedPath}`;
    };

    /*  const mapURLFE_ChampSelect = (urlPath) => {
            const lowercasedPath = urlPath.split("/").slice(3).join("/").toLowerCase();
            const mappedPath = rawCDN + "/latest/plugins/rcp-fe-lol-champ-select/global/default/" + lowercasedPath;
            return mappedPath;
        } 
    */

    const fetchChampData = async (id) => {
        try {
            const newVersion = await checkVersion();
            if (newVersion) {
                setCurrentVersion(newVersion);
                await Promise.all([getChampData(id)]);
                await preLoadImages();
            }
        } catch (error) {
            console.error(error);
        } finally {
            // Add a delay before setting isLoading to false (e.g., 1500 milliseconds or 1.5 seconds)
           /*  setTimeout(() => {
                setIsLoading(false);
            }, 1000); */
            setIsLoading(false)
        }
    };

    const preLoadImages = async () => {
        // Use requestAnimationFrame to ensure that this code runs after the DOM update
        const loadImage = async (img) => {
            if (img.complete) return;
            return new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
            });
        };
        requestAnimationFrame(() => {
            const images = document.querySelectorAll(".champLoad");
            const imageLoadingPromises = Array.from(images).map(loadImage);
            Promise.all(imageLoadingPromises).then(() => {
                console.log('All Images Loaded')
            })
            .catch((error) => {
                console.error("An error occurred while loading the images:", error);
            });
        });
    };

    useEffect(() => {
        setIsLoading(true);
        fetchChampData(id);
        setActiveTab(null);
    }, [id]);

    useEffect(() => {
        setIsCenterSplash(false)
        setSelectedSplash(0)
        setImagesPreloaded(false)
    }, [isLoading]);
    
    let selectedImgSplash = '';
    if (CDNChamp && CDNChamp.skins && CDNChamp.skins[selectedSplash]) {
        selectedImgSplash = isCenterSplash ? CDNChamp.skins[selectedSplash].splashPath : CDNChamp.skins[selectedSplash].uncenteredSplashPath;  
    }

    const handleSelect = (selected) => {
        setSelectedSplash(selected);
    };
    
    return (
        <>
            <LoaderSpin loadStatus={isLoading} isFull={true} />
            <div className={`champComponent ${isLoading ? 'hidden' : 'show'}`}>
                {/*<span className="dim off" id="dimmer"></span> */}
                <div className="main-container">
                    <CardNavigation champName={DDChamp?.name} champRegion={DDChamp?.region || (UNIChamp?.champion?.["associated-faction-slug"] || 'runeterra')} />
                    {!isLoading && <AbilitiesTab activeTab={activeTab}
                        switchTab={switchTab} /* Passing Function to Abilities Tab to be accessbile */
                        champData={DDChamp} /* Passing champ data  */
                        abilityData={CDNChamp}
                    />}

                    <span className="transbox" id='transbox'></span>

                    <aside className={`champ-detail ${activeTab === 'champ-detail' ? 'active' : ''}`} id="champ-detail">
                        <span className="champ-dim"
                            onClick={() => { switchTab('champ-detail'); bioActive() }}>
                        </span>
                    
                        <div className="champ-naming"
                            style={{visibility :`${(activeTab === null || activeTab === "champ-detail") ? "visible" :"hidden"} `}}
                            onClick={(e) => {
                                switchTab('champ-detail');
                                if (!e.target.parentElement.parentElement.classList.contains('active')) {
                                    /* audio runs only when opening champ bio*/
                                    audioPlay(e.currentTarget)
                                }
                                setTimeout(bioActive, 250); /* set Delay time ng animation: by value of champ voice 3/4 */
                            }}>
                            {CDNChamp?.stingerSfxPath &&
                                <audio id="champ-sfx" src={mapBEGamePath(CDNChamp.stingerSfxPath)} className='champLoad'></audio>
                            }
                            <strong className="champsName" >{DDChamp.name}</strong>
                            <h2 className="champsTitle">{DDChamp.title}</h2>
                            <img className="champ-divide" height={50} width={1942} src={headerBorder} alt="Divider" />
                        </div>
                        
                        <div className={`champ-extend ${activeTab === 'champ-detail' ? 'show-extend' : ''}`} >
                            <div className="bio-toggle" onClick={bioActive}>
                                <Avatar champ={CDNChamp} mapBEGamePath={mapBEGamePath}/>
                            </div>

                            <div className="bio-center">
                                <div className="bio-quote">
                                    {CDNChamp?.chooseVoPath && <AudioButton audioPaths={[mapBEGamePath(CDNChamp?.chooseVoPath), `${cdnSounds}sfx-cs-lockin-button-click.ogg`]}
                                        style={{ '--pick': '#03c4ff' }} label="Lock in"
                                    />}
                                    <div className="bio-quote-info">
                                        <p>{sanitizeText(UNIChamp?.champion?.biography?.quote || "Si Idol pala to Eh?")}</p>
                                        <span>~~ {UNIChamp?.champion?.biography?.["quote-author"] || DDChamp.name}</span>
                                    </div>
                                    {CDNChamp?.banVoPath && <AudioButton audioPaths={[mapBEGamePath(CDNChamp?.banVoPath), `${cdnSounds}sfx-cs-draft-ban-button-click.ogg`]}
                                        style={{ '--pick': '#bb1e37' }} label="Ban"
                                    />}
                                </div>
                                <div className="bio-description" style={{ '--bg': `url(${champSplash}${DDChamp?.id}_0.jpg)` }}>
                                    <span className="champ-lore">
                                        <p>{DDChamp.lore}</p>
                                        <a href={`https://universe.leagueoflegends.com/en_PH/story/champion/${DDChamp?.id}`}>
                                            <button className="champ-more">
                                                <span>Read Biography</span>
                                            </button>
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div className="bio-role bio-side left" onClick={bioRole}>
                                <div className="role-combat-card">
                                    <RoleFront champSole={DDChamp} />
                                    <CombatBack playstyle={playstyleInfo} tactical={tacticalInfo} />
                                </div>
                            </div>
                            <div className="bio-region bio-side right">
                                {(DDChamp?.region || UNIChamp?.champion?.['associated-faction-slug']) && (
                                    <RegionSide champRegion={DDChamp?.region || UNIChamp?.champion?.['associated-faction-slug']} champ={UNIChamp} mp={mapBEGamePath} />
                                )}
                            </div>
                        </div>
                    </aside>

                    <main className="page-wrap">
                        {CDNChamp?.id && <ChampSplash champData={CDNChamp} mapPath={mapBEGamePath} selectedImg={selectedImgSplash} selectedIndex={selectedSplash}/>}                        
                    </main>

                    <SkinTab activeTab={activeTab}
                        switchTab={switchTab}
                        mapBEPath={mapBEGamePath}
                        DDSole={DDChamp}
                        CDNSole={CDNChamp}
                        onSelect={handleSelect}
                        isPreloaded={imagesPreloaded}
                        isCenter={isCenterSplash}
                        setIsCenter={setIsCenterSplash}/>
                </div>
            </div>
        </>
    )
}

export default ChampDetail;