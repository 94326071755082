import champIconFrame from "./ChampIconFrame.png"
import headerBorder from "./HeaderDivider.png"

import abilityFrame from "./abilityframe.png"

import stylePip from "./pip.png"
import difficultyBar from "./difficultygraph_bg.png"

import championIcon from "./championIcon.png"
import navPoint from "./nav-point.png"

import controlSetting from "./control-settings.png"

//Skill
import skillOverlay from "./ability/skillOverlay.png"
import abilityBG from "./ability/skillBg.jpg"
import noAbility from "./ability/noAbility.png"

//Rarity Icons
import rareIcon from "./rarity/rare.png"
import epicIcon from "./rarity/epic.png"
import legendaryIcon from "./rarity/legendary.png"
import mythicIcon from "./rarity/mythic.png"
import ultimateIcon from "./rarity/ultimate.png"

//Logo
import brandLogo from "./Champflex.png"
import cdnLogo from "./communityDragon.png"

import riotLogo from "./riot-games/Riot Games RGB Logos (Web)/Full Lockup/Full Lockup Offwhite.png"
import riot2Logo from "./riot-games/Riot Games RGB Logos (Web)/Logomark/Logomark Offwhite.png"
import lolLogo from "./riot-games/lol-logo.png"
import lolFlatLogo from "./riot-games/lol-logo-flat.png"

//SVG
import { ReactComponent as FbSVG } from "./social/facebook.svg"
import { ReactComponent as EmailSVG } from "./social/email.svg"
import { ReactComponent as GithubSVG } from "./social/github.svg"
import { ReactComponent as LinkedinSVG } from "./social/linkedin.svg"

import { ReactComponent as HRSVG } from "./headline-hr.svg"

/* 
function importAll(r) {
    return r.keys();//.map(r); base 64 pag nakamap
}*/

function importAll(r) {
    let images = {};
    r.keys().map((item) => { return images[item.replace('./', '')] = r(item); });
    return images;
}

export const roleImages = importAll(require.context('../assets/role', false, /\.(png|jpe?g|svg)$/));
export const regionImages = importAll(require.context('../assets/region', false, /\.(png|jpe?g|svg)$/))
export const regionSplashImg = importAll(require.context('../assets/region/splash', false, /\.(png|jpe?g|svg)$/))
export const regionSplashVid = importAll(require.context('../assets/region/splashVid', false, /\.(webm|jpe?g|gif)$/))

export {
    champIconFrame,
    headerBorder, abilityFrame, stylePip, championIcon, controlSetting, difficultyBar, navPoint, skillOverlay, abilityBG, noAbility,
    rareIcon, epicIcon, legendaryIcon, mythicIcon, ultimateIcon, brandLogo, cdnLogo, riotLogo, riot2Logo, lolLogo, lolFlatLogo,
    FbSVG, EmailSVG, GithubSVG, LinkedinSVG, HRSVG
}