/* 
  TODO: Riot API 
    @param versionJSON : Version List  
*/

const versionJSON = "https://ddragon.leagueoflegends.com/api/versions.json";

let currentVersion = '';
export const getCurrentVersion = () => currentVersion;
export const setCurrentVersion = (version) => {
  currentVersion = version;
};

export const checkVersion = async () => {
  try {
    const response = await fetch(versionJSON);
    const data = await response.json();

    const latestVersion = data[0];
    //console.log(versionNew + ' - ' + data[0]);
    setCurrentVersion(latestVersion);
    return latestVersion;

  } catch (error) {
    console.error('Error while fetching version update:', error);
    throw new Error('Failed to fetch the latest version.');
  }
};

/* 
  TODO: Data Dragon API (dd/DD)
    * Main Source for Champion Lists - champion data 
    * Tags = Role
    * Skins

    ? ["id","key","name","title","image","skins","lore","blurb","allytips","enemytips",
    ? "tags","partype","info","stats","spells","passive","recommended","region"]
    
 ! Note: 
 ? Change:  export const champListAPI = `https://ddragon.leagueoflegends.com/cdn/${VersionNew}/data/en_US/champion.json`; 
 ?   to
 ?   This: export const champListAPI = () => `https://ddragon.leagueoflegends.com/cdn/${getCurrentVersion()}/data/en_US/champion.json`;
 ?   is a function that returns a string containing the URL for the champion data API with the current version obtained from getCurrentVersion()
 ?   is more flexible as it can be used to retrieve data for any champion, while the second example is limited to only the URL for the champion data API with the version specified at the time the code is run.
*/
export const ddChampListAPI = () => `https://ddragon.leagueoflegends.com/cdn/${getCurrentVersion()}/data/en_US/champion.json`;
export const ddChampJSON = () => `https://ddragon.leagueoflegends.com/cdn/${getCurrentVersion()}/data/en_US/champion/`;//Aatrox.json
export const champSplash = `https://ddragon.leagueoflegends.com/cdn/img/champion/splash/`;//champname_0.jpg_

export const champImgSkill = () => `https://ddragon.leagueoflegends.com/cdn/${getCurrentVersion()}/img/spell/`; //RyzeW.png
export const champImgPassive = () => `https://ddragon.leagueoflegends.com/cdn/${getCurrentVersion()}/img/passive/`; //Anivia_P.png

/* export const champVidPassive = "https://d28xe8vt774jo5.cloudfront.net/champion-abilities/"; //P1 */
export const champVidSkill = `https://d28xe8vt774jo5.cloudfront.net/champion-abilities/`;
//https://d28xe8vt774jo5.cloudfront.net/champion-abilities/0266/ability_0266_R1.webm
//https://d28xe8vt774jo5.cloudfront.net/champion-abilities/0266/ability_0266_E1.mp4
//https://d28xe8vt774jo5.cloudfront.net/champion-abilities/0(champKey)/ability_0(champKey)_(Q1).webm or mp4

export const searchIcon = `https://cdn-icons-png.flaticon.com/512/3917/3917754.png`;

/* 
  TODO: Community Dragon API (cdn/CDN)
    * Main Source for Spells and SpellbookOverride (Hwei)
    * Tactical and Playstyle info 
    * SFX Sound Effect - Pick/Ban Voice Lines

    ? ["id","alias","title","name","shortBio","tacticalInfo","playstyleInfo","squarePortraitPath",
    ? "stingerSfxPath","chooseVoPath","banVoPath","roles","recommendedItemDefaults","skins","passive","spells"]
    ? + spellbookOverride
*/

export const rawCDN = "https://raw.communitydragon.org";

//cdnChampJSON requires (champ.KEY.json) at end
export const cdnChampJSON = `${rawCDN}/latest/plugins/rcp-be-lol-game-data/global/default/v1/champions/`;

export const cdnSounds = `${rawCDN}/latest/plugins/rcp-fe-lol-champ-select/global/default/sounds/`;

export const cdnCursor = `${rawCDN}/pbe/game/assets/ux/cursors/hand1.png`

export const cdnSpellIcon = `${rawCDN}/latest/plugins/rcp-be-lol-game-data/global/default/assets/characters/`; //hwei/hud/icons2d

export const cdnRarityGem = `${rawCDN}/latest/plugins/rcp-be-lol-game-data/global/default/v1/rarity-gem-icons/`

/* 
  TODO: LoL Universe Champion JSON (uni/UNI)
    * Main Source for a champtions biography, region, and relationships 
    
  ? ["id","name","title","locale","champion","related-champions","explore-champions","modules"]

  ? https://universe-meeps.leagueoflegends.com/v1/en_us/champions/aatrox/index.json 
*/

export const uniChampJSON = `https://universe-meeps.leagueoflegends.com/v1/en_us/champions/`
export const uniRegion = "https://universe-meeps.leagueoflegends.com/v1/en_us/factions/" //+mount-targon/index.json
