import { useNavigate } from "react-router-dom";
import { championIcon, controlSetting, navPoint, regionImages } from "../../assets";

const CardNavigation = ({ champName, champRegion }) => {
    const navigate = useNavigate();
    const regionImage = regionImages[`${champRegion.toLowerCase()}.png`];
    return (
        <div className="nav-bar">
            <div className="nav-main">
                <button className="back-nav" title="To Home" onClick={() => navigate('/')}>
                    <svg viewBox="0 0 83.64 100">
                        <polygon points="83.58 58.21 41.79 100 0 58.21 0 30.67 31.78 62.44 31.78 0 52.03 0 52.03 62.21 83.64 30.61 83.58 58.21"></polygon>
                    </svg>
                </button>
                <span className="nav-divider" />
                <img src={championIcon} alt="champions-icon" className="champ-icon" width="18" height="32" />
                <a href="/" title="Champion List">Champions</a>
                <img src={navPoint} alt="point-nav" className="champ-icon" width="11" height="9" />
                <img src={regionImage} alt={champRegion} className="champ-icon" height={24} width="25" />
                <span>{champName}</span>
            </div>
            <div className="nav-side">
                <img src={controlSetting} alt='settings-icon' className="champ-icon" width="32" height="32" />
            </div>
        </div>
    )
}

export default CardNavigation;