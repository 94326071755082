import { brandLogo, riotLogo, cdnLogo, riot2Logo, lolLogo, FbSVG, EmailSVG, GithubSVG, LinkedinSVG, lolFlatLogo } from "../../assets";
import React from "react";

const HomeFooter = () => {

    return (
        <footer className="card-footer">
            <div className='copyright-content'>
                <div className="copyright-section about">
                    <img className="about-logo" src={brandLogo} width={20} height={5} alt="logo" title="ChampFlex" />
                    <p>ChampFlex is not endorsed by Riot Games and does not reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties.</p>
                </div>
                <div className="copyright-section links">
                    <div className="links-logo">
                        <img src={cdnLogo} alt="cdn" title="Community Dragon" />
                        <img src={riotLogo} alt="riot" title="Riot Games | Data Dragon" />
                        <img src={lolFlatLogo} alt="lol" title="League of Legends" />
                        {/* <img src={riot2Logo} alt="riot2" title="Riot's Data Dragon" /> */}
                    </div>
                    <div className="disclaimer">
                        <p className='copyright-info'> ™ & © 2023 Riot Games, Inc. League of Legends and all related logos, characters, names, and distinctive likenesses thereof are the exclusive property of Riot Games, Inc. All rights reserved.</p>
                        <p>This website uses Riot Games' API. The data presented here is for demonstration purposes only and is not intended for commercial use. <br /></p>
                    </div>

                </div>
                <div className="copyright-section contact">
                    <p>Mik10</p>
                    <div className="contacts">
                        <a href="/facebook.com" title="fb">
                            <FbSVG />
                        </a>
                        <a href="/email.com" title="email">
                            <EmailSVG className="icon" />
                        </a>
                        <a href="/linkedout.com" title="linkedin">
                            <LinkedinSVG className="icon" />
                        </a>
                        <a href="/igithub.com" title="github">
                            <GithubSVG className="icon" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="copyright-author">
                <p>
                    Copyright &copy; Mik10 - 2023. All rights reserved. This website is not affiliated with or endorsed by Riot Games, Inc.
                </p>
            </div>

        </footer>
    )
}

export default React.memo(HomeFooter);