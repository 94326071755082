import React, { useEffect } from 'react';

//Components
import HomeHeader from '../components/Home/Home-Header';
import HomeSection from '../components/Home/Home-Section';
import HomeFooter from '../components/Home/Home-Footer';
import usePreconnect from '../utils/Preconnect';

const Home = () => {
    document.title = `Champ-Flex`
    usePreconnect(["https://ddragon.leagueoflegends.com"]);

    useEffect(() => { 
        return () => {console.log('Fetching Stops');};
    }, []);

    return (
        <>
            <HomeHeader />
            <HomeSection />
            <HomeFooter />
        </>
    );
}
export default Home;