import { Link } from "react-router-dom";
import { champIconFrame } from "../../../assets"
import { useChampListContext } from "../../../context/ChampListContext";

const Avatar = ({ champ, mapBEGamePath }) => {
    const { champList } = useChampListContext();//using champList fetched from the Home component 
    const relatedChamp = Object.values(champList).find(
        (champAPI) => champAPI.name === champ.name
    );

    return (
        <div className="avatar-icon">
            {champ?.squarePortraitPath ? //if the source is the main champ *from community dragon show the square portrait
                <>
                    {/* <div className="avatar-img" style={{ backgroundImage: `url(${mapBEGamePath(champ.squarePortraitPath)})` }}></div> */}
                    <div className="avatar-container ">
                        <img className="avatar-img champLoad" src={mapBEGamePath(champ.squarePortraitPath)} alt="Champe" />
                    </div>
                    <img className="icon-frame" src={champIconFrame} alt="Champ Frame" />
                </>
                :
                champ?.image ? //if its an icon for related champion change source to LoL univ calculate the position to focus champ image e
                    <>
                        {/* 
                            In order for us to get the id of related champion since it is from a different souce and of different format
                            We first get the ChampList which we based our whole ChampionDetail Object 
                            API 1 id: 'KogMaw' and API 2 no id but name: "Kog'Maw"
                            so what we can do is to  use context for champ list to find the object from api1 for the related champs
                        */}
                        {relatedChamp && (
                            <Link to={`/champ/${relatedChamp.id}`} className="champLink" key={champ.name}>
                                <div className="avatar-container"
                                    style={{
                                        backgroundImage: `url(${champ.image.uri})`, backgroundSize: '250%',
                                        backgroundPosition: `${(champ.image.x / champ.image.width) * 100}% ${(champ.image.y / champ.image.height) * 100}%`
                                    }}>
                                </div>
                                <p>{champ.name}</p>
                            </Link>
                        )}
                        {/* 
                            <Link to={`/champ/${(champ.url.split('/').pop())}` } className="champLink">
                                <p>{champ.name}</p>
                            </Link> 
                        */}
                    </>
                    : ''
            }
        </div>
    )
}

export default Avatar;