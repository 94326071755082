import { roleImages } from "../../../assets"

const RoleFront = ({ champSole }) => {
    return (
        <div className="role-front">
            <div className="role-title">Role</div>
            <div className="role-container">
                {champSole.tags?.map((role) => {
                    return (
                        <span className="role-info" key={role}>
                            <img
                                src={(roleImages[`${role.toLowerCase()}.png`])}
                                alt={role}
                            />
                            <p className="role-label">
                                {role}
                            </p>

                        </span>
                    )
                })}
            </div>
        </div>

    )
}

export default RoleFront;