import React, { useState } from "react";
import { regionImages } from "../../assets";
import { Link } from "react-router-dom";

const champSplash = "https://ddragon.leagueoflegends.com/cdn/img/champion/loading/";//champiamge
const ChampCard = ({ champ }) => {
    const [isLoading, setIsLoading] = useState(true);
    const handleImageLoad = () => {
        // Image has loaded, remove the loading class
        setIsLoading(false);
    };

    return (
        <Link to={`/champ/${champ.id}`}
            className='champ-card' style={{ '--ct': `${champ.colorTheme}`, backgroundColor: `${isLoading ? 'blue' : 'red'}` }}>
            <img src={`${champSplash}${champ?.id}_0.jpg`} alt={`${champ.name}-card`}
                className={"card-image"} style={{ display: `${isLoading ? 'none' : 'block'}` }}
                onLoad={handleImageLoad}// This event handler triggers when the image is loaded
            />
            <div className="champ-part" >
                <img className="champ-region" src={regionImages[`${champ.region.toLowerCase().replace(/\s+/g, '')}.png`]} alt={champ.region} loading="lazy" />
                <span className="champ-name">{champ.name}</span>
                <span className="champ-title">{champ.title}</span>
            </div>
        </Link>
    )
}

export default ChampCard;