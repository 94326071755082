import { useEffect } from 'react';

export default function usePreconnect(urls) {
  useEffect(() => {
    const links = urls.map(url => {
        const link = document.createElement('link');
        link.rel = 'preconnect';
        link.href = url;
        document.head.append(link);
        console.log(url)
        return link;
      });

    return () => {
        links.forEach(link => document.head.removeChild(link)); //cleanup so before component is removed it also removes the link to avoid memory leaks.
      
    };
  }, []); // This effect runs whenever urls changes
}
