//Assets
import { roleImages, regionImages, regionSplashVid, regionSplashImg, championIcon, headerBorder } from '../../assets';
//Components
import ChampCard from './ChampCard';
import LoaderSpin from '../LoaderSpin';

import { uniRegion } from '../../config/RiotAPI';

//Animation
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';

//Contexts
import { useChampListContext } from '../../context/ChampListContext';


const RegionSection = ({ region, role }) => {
    const [regionActive, setRegionActive] = useState();
    const { sanitizeText } = useChampListContext()
    const regionMap = {
        "Bandle City": "bandle-city", "Bilgewater": "bilgewater", "Demacia": "demacia", "Ionia": "ionia",
        "Ixtal": "ixtal", "Noxus": "noxus", "Piltover": "piltover", "Shadow Isles": "shadow-isles",
        "Shurima": "shurima", "Targon": "mount-targon", "Freljord": "freljord", "The Void": "void", "Zaun": "zaun"
    }
    const runeterraShort = (
        <p>
            Runeterra (meaning magic earth) is a plane of existence composed of the physical realm: an oblate spheroid planet and the Spirit Realm.
            Runeterra is a world brimming with life and magic, and is composed of two realms, the Material and Spirit Realms, which are linked to and directly mirror one another.
            The Material Realm; the primary setting for the League of Legends universe, where the various continents, oceans, nations and regions can be found.
            The Spirit Realm; a place where the souls of every person, creature, place and feeling can be found.
        </p>
    );
    // https://universe-meeps.leagueoflegends.com/v1/en_us/faction-browse/index.json 

    const fetchRegion = async () => {
        try {
            if (region === "Runeterra") {
                setRegionActive(null);
                return
            }
            const response = await fetch(uniRegion + regionMap[region].toLowerCase() + "/index.json");

            if (!response.ok) {
                throw new Error(`Failed to fetch data. Status: ${response.status}`);
            }
            const data = await response.json();

            setRegionActive(data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchRegion()
    }, [region])

    return (
        <>
            <div className='region-info'>
              <div className='region-dim'>
                <img className="faction-emblem" src={regionImages[`${region.toLowerCase()}_emblem.png`]} key={region} alt={region} />
                <div className='faction-info'>
                  <h1 >{region}</h1>
                  <img height={50} width={1942} src={headerBorder} alt="Divider" />
                </div>
                
              </div>
              {regionSplashVid[`${region.toLowerCase()}-splashvideo.webm`] ? (
                <video autoPlay loop muted preload="auto" className='region-bg'
                  src={regionSplashVid[`${region.toLowerCase()}-splashvideo.webm`]}>
                </video>)
                : (
                <img className='region-splash' src={regionSplashImg[`${region.toLowerCase()}_splash.png`]} alt={region} />
              )}
              
            </div>
            <div className="region-overview">
              <div className='region-short'>
                {(region !== "Runeterra") ? sanitizeText(regionActive?.faction?.overview.short)
                    : runeterraShort}
              </div>
            </div>

            <div className='region-content'>
              <img src={regionImages[`${region.toLowerCase()}_emblem.png`]} className='region-bg' 
                alt={region} key={region} 
              />

              <div className="region-caption">
                {role && <img src={roleImages[`${role.toLowerCase()}.png`]} alt={`${role}.png`} className="caption-role" />}
                <div className="region-tag">
                  <figure className="bracket">
                    <div className="bracket-line left"></div>
                    <div className="bracket-point"></div>
                  </figure>
                  {role ? (<h1>{role}'s <em> of {region}</em></h1>)
                    : (<h1>Champion's <em> of {region}</em></h1>)
                  }
                  <figure className="bracket">
                    <div className="bracket-point"></div>
                    <div className="bracket-line right"></div>
                  </figure>
                </div>
              </div>
            </div>
        </>
    )
}

const HomeSection = () => {
  const { champList, errorMessage, isLoading,
    filterRegion, filterRole,
    searchTerm, setSearchTerm,
    setFilterRegion, setFilterRole,
    uniqueRegion, uniqueRole,
  } = useChampListContext();

  const filteredItems = (champs) => champs.filter( 
    (item) =>
        item.name.toLowerCase().startsWith(searchTerm.trim().toLowerCase()) && //or includes(searchTerm) to return matching search term
        (filterRole === '' ? item.tags : item.tags.includes(filterRole)) &&
        (item.region === (filterRegion === '' ? item.region : filterRegion))
  );

  return (
    <section id='card-section' className="card-section">
      <div className='header-search'>
        <div className='search-input'>
          <img src={championIcon} alt="champion Icon" className="champ-icon" width="18" height="32"/>
          <input className='search-bar' id='search-Bar' placeholder='Search Champion'
              onChange={(e) => setSearchTerm(e.target.value)}value={searchTerm} />
        </div>
        <div className='search-filter'>
          {champList?.length > 0 ? (
            <>
              <label className='search-region'>
                <select className='region-filter' value={filterRegion} title='Select Region' name="region-select" id="region-select" onChange={(e) => setFilterRegion(e.target.value)}>
                    <option value='' >Select Region</option>
                    {uniqueRegion.map((region, index) => (
                        <option value={region} key={index}>{region}</option>
                    ))}
                </select>
                <span className='filter-icon'></span>
              </label>

              <label className='search-role'>
                <select value={filterRole} className='role-filter' title='Select Role' name="role-select" id="role-select" onChange={(e) => setFilterRole(e.target.value)}>
                  <option value=''>Select Role</option>
                  {uniqueRole.map((role, index) => (
                      <option value={role} key={index}>{role}</option>
                  ))}
                </select>
                <span className='filter-icon'></span>
              </label>
            </>
          ) : (<option value="">--</option>)}
        </div>
      </div>
      {filterRegion /* if region select */ && (
        <RegionSection region={filterRegion} role={filterRole} />
      )}

      {errorMessage && (
        <div style={{ display: "block" }} className="card-noload">
          {errorMessage}
        </div>
      )}
      <div className="card-container">
        {isLoading && (
          <div className="card-load">
            <LoaderSpin loadStatus={isLoading} isFull={false} />
          </div>
        )}
        {
          //CHAMPION LIST RENDER
          champList?.length > 0 && filteredItems(champList).length > 0 ? ( //from champList && champList?.length > 0 to champList?.length
            <AnimatePresence>
              {filteredItems(champList)
                .sort((a, b) => (a.name > b.name ? 1 : -1)) // sort alphabetically
                .map((champ) => (
                  <motion.div key={champ.key} layout initial={{ opacity: 0 }} exit={{ opacity: 0 }}
                    animate={{ opacity: 1 }} transition={{ duration: 0.4 }}>
                    <ChampCard champ={champ} />
                  </motion.div>
                ))}
            </AnimatePresence>
          ) : (
            //if data is empty
            <div style={{ color: "red" }}>
              No Results <p>Check connection</p>
            </div>
          )
        }
      </div>
    </section>
    );
}

export default HomeSection;