//Styles
import './styles/Home.css'
import './styles/ChampCard.css'
import './styles/ChampDetail.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Components
import Home from './container/Home';
import ChampDetail from './container/ChampDetail';
import ChampListProvider from './context/ChampListContext';

function App() {

  return (
    <ChampListProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/champ/:id' exact element={<ChampDetail />} />
        </Routes>
      </Router>
    </ChampListProvider>
  );
}

export default App;
