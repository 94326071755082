import React from 'react';

const LoaderSpin = ({ loadStatus, isFull }) => {

  return (
    isFull ?
      <div className={`loadSpin-Container ${loadStatus ? 'show' : 'hidden'}`} >
        <svg className='loadCircle' height="80" width="80">
          <circle cx="40" cy="40" r="40" fill='transparent' stroke='#4c4c3c' strokeWidth={2} strokeDasharray={8} />
        </svg>
        <div className="loadingSpin">
          <span className='loadLeft'>
            <svg height="40" width="40">
              <polygon points="20,0 40,20 20,40 0,20" fill='transparent' stroke='#6c6044' strokeWidth={1.5} />
            </svg>
            <svg height="80" width="80">
              <polygon points="40,0 80,40 40,80 0,40" fill='transparent' stroke='#4c4c3c' strokeWidth={.5} />
            </svg>
          </span>

          <span className='loadCenter'></span>

          <span className='loadRight'>
            <svg height="40" width="40">
              <polygon points="20,0 40,20 20,40 0,20" fill='transparent' stroke='#6c6044' strokeWidth={1.5} />
            </svg>
            <svg height="80" width="80">
              <polygon points="40,0 80,40 40,80 0,40" fill='transparent' stroke='#4c4c3c' strokeWidth={.5} />
            </svg>
          </span>

        </div>
      </div>
      :
      <div className={`loadSpin-Home ${loadStatus ? 'show' : 'hidden'}`} >
        <div className="loadingSpin"></div>
      </div>
  );
};
export default LoaderSpin;