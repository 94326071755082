const HomeHeader = () => {
    /* center the filter role idea must be directed to home header 
    const setCenter = () => 
        if role is selected
            prevActiveRole =  pos selectedRole 
            selectedRole = pos prevActiveRole[3]
            and scale size            
    } */

    return (
        <header className='header-home'>
            <div className='header-hero'>
                <h4>Mik10's</h4>
                <h1>Champ Flex</h1>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 129" version="1.1">
                    <g>
                        <path fill="#c8a152" opacity="1.00" d=" M 630.48 4.18 C 633.18 4.21 635.96 3.84 638.62 4.41 C 648.29 14.03 657.77 23.87 667.46 33.49 C 674.97 33.88 682.52 33.45 690.05 33.67 C 694.17 36.61 697.22 40.83 701.08 44.11 C 712.67 44.52 724.30 44.05 735.90 44.32 C 741.23 47.93 745.39 53.80 750.87 56.89 C 755.73 52.86 760.02 48.05 765.10 44.33 C 776.69 44.02 788.33 44.57 799.92 44.07 C 803.80 40.84 806.79 36.51 810.96 33.67 C 818.70 33.29 826.65 34.18 834.27 33.27 C 844.16 23.86 853.60 13.93 863.38 4.39 C 866.01 3.87 868.75 4.18 871.44 4.19 C 862.20 14.28 851.73 23.31 842.76 33.61 C 1061.04 33.64 1279.32 33.65 1497.60 33.60 C 1497.72 31.54 1497.61 29.47 1497.83 27.41 L 1499.54 27.42 C 1499.76 30.10 1499.74 32.81 1499.59 35.50 C 1279.88 35.68 1060.16 35.44 840.44 35.62 C 810.12 65.34 780.52 95.85 750.20 125.58 C 720.32 95.77 690.98 65.34 661.07 35.58 C 440.71 35.54 220.36 35.54 0.00 35.58 L 0.00 27.11 C 3.36 27.21 1.45 31.48 2.15 33.65 C 221.13 33.56 440.11 33.73 659.09 33.57 C 649.82 23.53 639.84 14.13 630.48 4.18 M 670.13 35.51 C 671.92 38.90 675.43 41.29 678.04 44.14 C 684.71 44.30 691.44 44.43 698.11 44.07 C 695.36 41.15 692.56 38.26 689.58 35.59 C 683.10 35.48 676.61 35.63 670.13 35.51 M 803.24 44.26 C 809.84 44.23 816.45 44.30 823.05 44.23 C 826.20 41.79 829.18 38.91 831.49 35.66 C 824.71 35.65 817.76 35.16 811.08 35.90 C 808.45 38.63 805.05 40.96 803.24 44.26 M 683.94 50.20 C 705.89 72.52 727.76 95.03 750.01 117.02 C 751.29 116.93 751.97 115.62 752.89 114.89 C 774.26 93.25 796.14 72.08 817.19 50.16 C 810.25 50.25 803.20 49.78 796.33 50.39 C 781.29 65.66 765.76 80.43 750.70 95.69 C 735.08 80.98 719.89 65.59 704.66 50.39 C 697.82 49.79 690.83 50.21 683.94 50.20 M 707.39 50.16 C 721.43 64.56 735.83 78.69 750.18 92.80 C 765.10 79.51 779.24 64.40 793.57 50.16 C 784.70 50.10 775.84 50.09 766.99 50.16 C 761.59 55.04 756.20 59.93 750.86 64.87 C 744.83 60.85 739.72 54.90 734.02 50.18 C 725.14 50.06 716.26 50.11 707.39 50.16 Z" />
                        <path fill="#c8a152" opacity="1.00" d=" M 750.12 12.31 C 754.33 18.06 757.62 26.13 761.16 32.91 C 758.16 36.61 754.39 39.70 751.17 43.23 C 750.39 44.40 749.07 43.25 748.47 42.56 C 745.40 39.33 742.11 36.31 739.12 33.02 C 742.69 26.13 745.92 18.59 750.12 12.31 Z" />
                    </g>
                </svg>
            </div>
        </header>
    )
}
export default HomeHeader;

{/* 
    <div className='role-List'>
        <img src={'https://universe.leagueoflegends.com/esimages/content_type_icon_champion__3nwJQ.png'} alt={'champs'}/>
            {roleKeys.map((key) => {
                return <img src={roleImages[key]} key={key}alt={key}/>
        })}
    </div> 
*/}