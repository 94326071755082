import React from "react";
import { stylePip, difficultyBar } from '../../../assets'

const CombatBack = ({ tactical, playstyle }) => {

    const movePin = (value) => {
        /*Calculating position based on 20px - 120px range of possilbe position for the pin to move.
        And using the values 1 and 10 (given Style Value)
        we compute the pin position = minPosition + ((value - minValue) * (maxPosition-minValue))/ (maxValue - minValue) 
        */
        const pin = document.getElementById('style-pin')
        const basic = document.getElementById('style-basic')
        const skill = document.getElementById('style-skill')

        if (pin) {
            const position = 20 + ((value - 1) * (120 - 20)) / (10 - 1);
            /* Setting the pin positon  */
            pin.style.transform = `translateX(${position}px)`;

            if (value > 5) {
                skill.classList.add("style-lit")
                basic.classList.remove("style-lit")
            } else {
                basic.classList.add("style-lit")
                skill.classList.remove('style-lit');
            }
        }
    }

    const getDiffFill = (i) => {
        const diffValue = tactical?.difficulty || 1;
        /* so each of the path has a getDiffFill function 
        getDiffFill(0) for the first path, meaning if the value of difficulty is 1 
        we compare the index which is 0, 0 < 1 then check the corresponding color 
        */
        if (i < diffValue) {
            if (i === 0) return '#0097ac';
            if (i === 1) return '#70b6c2';
            if (i === 2) return '#b0d9db';
        }
        return 'transparent'
    }

    return (

        <div className="combat-back">
            <div className="combat-title">Combat</div>
            <div className="combat-container">
                <div className="tactical-container">
                    <div className="tactical-info">
                        <p style={{ marginBottom: "4px" }}> Damage:</p>
                        <p style={{ alignSelf: 'center', color: '#f8e4d4' }}>{tactical?.damageType.replace(/^./, '')}</p>
                    </div>

                    {/* Difficulty Bar */}
                    <div className="difficulty-info">
                        <p>Difficulty:</p>
                        <div className="difficulty-bar">
                            <svg className="difficulty-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 16">
                                <g>
                                    <path fill={getDiffFill(0)} d="M 0 0 C 50.531 0 102.957 0.074 155.02 0.074 C 155.02 5.354 154.799 10.634 154.799 16.074 C 104.268 16.074 52.062 16 0 16 C 0 10.72 0 5.44 0 0 Z"></path>
                                    <path fill={getDiffFill(1)} d="M 167.378 -0.015 C 326.251 -0.015 158.142 -0.038 321.829 -0.038 C 321.829 5.341 321.829 10.719 321.829 16.261 C 162.956 16.261 331.146 16.31 167.459 16.31 C 167.459 10.931 167.378 5.527 167.378 -0.015 Z"></path>
                                    <path fill={getDiffFill(2)} transform="translate(337,0)" d="M -2.048 -0.067 C 48.112 -0.067 100.32 0 152 0 C 152 5.28 152 10.56 152 16 C 101.84 16 49.632 15.933 -2.048 15.933 C -2.048 10.653 -2.048 5.373 -2.048 -0.067 Z"></path>
                                    <title>Difficulty - The champion's difficulty rating is ({tactical?.difficulty})</title>
                                </g>
                            </svg>
                            <img src={difficultyBar} alt="difficulty bar bg" className="difficulty-bg" />
                        </div>
                    </div>

                    {/* Style Slide */}
                    <div className="style-info">
                        <p>Style: {movePin(tactical?.style)} </p>
                        <div className="style-slide">
                            <img className="style-pin" id="style-pin" src={stylePip} alt="pip" />

                            <svg className="style-svg" width="296" height="57" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296 57">
                                <g className="style-icon">
                                    <path id="style-basic"
                                        d="M 2.184 13.75 C 2.492 16.429 2.683 16.508 9.564 16.79 C 15.512 17.034 17.053 16.746 19.314 14.967 C 20.791 13.806 22 12.438 22 11.927 C 22 11.417 17.47 11 11.934 11 L 1.867 11 L 2.184 13.75 M 14.339 24.199 C 2.093 36.306 2 36.432 2.052 40.949 L 2.104 45.5 L 16.551 30.5 C 30.74 15.769 33.243 12 28.839 12 C 27.417 12 22.459 16.171 14.339 24.199 M 35.641 19.675 C 34.619 21.044 31.919 22.965 29.641 23.943 C 24.122 26.313 17.001 34.076 16.996 37.728 C 16.994 39.252 16.101 41.962 15.011 43.75 L 13.029 47 L 16.365 47 C 23.393 47 31.483 42.544 35.023 36.722 C 36.848 33.721 38.52 23.397 37.845 19.29 C 37.51 17.247 37.447 17.258 35.641 19.675 M 2 21.5 C 2 23.725 2.415 24 5.777 24 C 9.503 24 14 21.781 14 19.943 C 14 19.424 11.3 19 8 19 C 2.267 19 2 19.111 2 21.5 M 2 29.674 L 2 32.349 L 4.5 30 C 7.587 27.1 7.587 27 4.5 27 C 2.457 27 2 27.488 2 29.674"
                                        fillRule="evenodd" stroke="none">
                                    </path>
                                    <path
                                        d="M 44.209 26.377 C 43.842 26.743 43.542 28.093 43.542 29.377 L 43.542 31.71 L 146.542 31.71 L 249.542 31.71 L 249.542 28.71 L 249.542 25.71 L 147.209 25.71 C 90.925 25.71 44.575 26.01 44.209 26.377"
                                        fillRule="evenodd" stroke="none">
                                    </path>
                                    <path id="style-skill"
                                        d="M 265.654 19.433 C 256.876 28.244 255.997 29.433 258.256 29.433 C 259.667 29.433 261.105 29.717 261.452 30.064 C 261.799 30.411 259.81 34.911 257.032 40.064 C 254.135 45.438 252.508 49.433 253.216 49.433 C 253.895 49.433 258.166 47.408 262.706 44.933 C 267.247 42.458 271.23 40.433 271.558 40.433 C 271.886 40.433 272.154 41.558 272.154 42.933 C 272.154 44.308 272.565 45.433 273.068 45.433 C 274.708 45.433 292.038 26.245 292.373 24.059 C 293.043 19.686 291.346 13.995 288.692 11.712 C 286.667 9.97 284.814 9.433 280.829 9.433 L 275.616 9.433 L 265.654 19.433"
                                        fillRule="evenodd" stroke="none">
                                    </path>
                                    <title> Style ({tactical?.style}) - The champion's tendency to deal damage to enemy champions using basic attacks vs abilities.</title>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                {/* Combat Wheel */}
                <div className="combat-wheel">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.772535 59.1636" className="combat-svg">
                        <circle cx="30.886295" cy="32.257652" r="23.812559" fill="#020a15" className="combat-background"></circle>
                        <g className="combat-section stat-damage" >
                            <path className="combat-hover" fillOpacity="0" d="M27.688902 27.406997a5.820833 5.820833 0 0 1 6.39233.003l12.40561-17.07486L33.374462.808432c-1.48369-1.077833-3.49262-1.077916-4.9764-.000208l-13.11312 9.526843z"></path>
                            <g className="combat-stat" >
                                <path className={`${playstyle?.damage >= 1 ? 'combat-bar' : 'transparent'}`} d="M30.885316 22.742002c-1.7509-.000688-3.501667.48051-5.045687 1.44384l1.87275 2.57762c1.96417-1.13245 4.38338-1.131683 6.3469.0021l1.8712-2.575554c-1.54332-.96461-3.29427-1.44729-5.04517-1.44797z" ></path>
                                <path className={`${playstyle?.damage >= 2 ? 'combat-bar' : 'transparent'}`} d="M30.8848 16.928404c-2.95056-.0014-5.901773.846566-8.469768 2.54403l1.870687 2.575037c4.01802-2.59748 9.18497-2.60006 13.20539-.0062l1.860862-2.56056c-2.566413-1.699912-5.51662-2.55091-8.46718-2.5523z" ></path>
                                <path className={`${playstyle?.damage >= 3 ? 'combat-bar' : 'trnasparent'}`} d="M30.88635 11.116872c-4.149605-.000356-8.29972 1.217195-11.888164 3.65249l1.86862 2.57142c6.0613-4.07 13.980905-4.073286 20.045805-.0088l1.86087-2.560568c-3.588-2.43587-7.73752-3.654208-11.88713-3.65456z" ></path>
                            </g>
                            <path className="combat-icon" d="M28.981582 1.830358l-.47625.47625.83344.83344-.53579.53578.47626.47625.53578-.53578.10453.13069.56519-.45218-.19347-.15476.53578-.53578-.47625-.47625-.53578.53578zm3.81 0l-.83344.83344-.53578-.53578-.47625.47625.53578.53578-2.97656 2.38125v1.07156h1.07156l2.38125-2.97656.53578.53578.47625-.47625-.53578-.53578.83344-.83344zm-.85957 2.62229l-.7239.90494.98822 1.23527h1.07156v-1.07156l-1.33582-1.06865z"></path>
                            <title>Damage - A champion's ability to deal damage.</title>
                        </g>
                        <g className="combat-section stat-toughness">
                            <path className="combat-hover" fillOpacity="0" d="M34.511532 27.717797a5.820833 5.820833 0 0 1 1.97239 6.08042l20.0727 6.52202 5.00849-15.41453c.56659-1.74414-.0541-3.65477-1.5376-4.7329l-13.11274-9.52736z"></path>
                            <g className="combat-stat" >
                                <path className={`${playstyle?.durability >= 1 ? 'combat-bar' : 'transparent'}`} d="M37.00329 24.963572l-1.872755 2.57762c1.68398 1.51809 2.43091 3.819132 1.959572 6.036842l3.028238.98392c.88099-3.53173-.328136-7.256952-3.115055-9.598382z" ></path>
                                <path className={`${playstyle?.durability >= 2 ? 'combat-bar' : 'transparent'}`} d="M40.427887 20.250165L38.5572 22.8252c3.71199 3.018712 5.311032 7.932215 4.086572 12.557375l3.01015.977718c1.647303-5.93222-.410026-12.274648-5.226035-16.110128z" ></path>
                                <path className={`${playstyle?.durability >= 3 ? 'combat-bar' : 'transparent'}`} d="M43.84525 15.547093l-1.86862 2.571936c5.743848 4.50694 8.19479 12.03777 6.20324 19.06188l3.01015.97823c2.415792-8.33024-.49491-17.291498-7.34477-22.612058z" ></path>
                            </g>
                            <path className="combat-icon" d="M55.518632 21.210057v1.78594c0 1.87101 2.04103 2.97656 2.04103 2.97656s2.04103-1.10555 2.04103-2.97656v-1.78594s-.93554.68032-1.36071.68032l-.68032-.68032-.68032.68032c-.42523 0-1.36071-.68032-1.36071-.68032z"></path>
                            <title>Toughness - A champion's ability to survive being focused.</title>
                        </g>

                        <g className="combat-section stat-control">
                            <path className="combat-hover" fillOpacity="0" d="M36.324262 34.302537a5.820833 5.820833 0 0 1-5.17333 3.75481v21.10569h16.2078c1.83386-.00011 3.45917-1.18086 4.02611-2.92489l5.009-15.41508z"></path>
                            <g className="combat-stat">
                                <path className={`${playstyle?.crowdControl >= 1 ? 'combat-bar' : 'transparent'}`} d="M36.683413 34.836872c-.923412 2.07068-2.880795 3.491932-5.135605 3.728972v3.183784c3.63111-.25349 6.80027-2.55425 8.16591-5.92832z"></path>
                                <path className={`${playstyle?.crowdControl >= 2 ? 'combat-bar' : 'transparent'}`} d="M42.227776 36.638312c-1.72389 4.46314-5.902797 7.50224-10.679968 7.766968v3.165182c6.15092-.26648 11.547126-4.183227 13.706658-9.948747z"></path>
                                <path className={`${playstyle?.crowdControl >= 3 ? 'combat-bar' : 'transparent'}`} d="M47.760256 38.436135c-2.51141 6.85544-8.916708 11.51349-16.212448 11.78998v3.16518c8.66905-.27663 16.29209-5.81405 19.23552-13.972792z"></path>
                            </g>
                            <path className="combat-icon" d="M47.268722 52.566027c-.86481.00067-1.71432.44428-2.17235 1.28468-.22485.41259-.3216.87946-.29725 1.33517.0406.7595.41791 1.48786 1.10127 1.88232.91791.52988 2.15241.23974 2.65986-.76891.41294-.82075.16755-1.91994-.66866-2.34904-.65079-.33394-1.51977-.10934-1.82904.63556-.1124.27072-.12169.57538-.0321.84957.0896.2742.29175.52373.58908.62835.19355.0681.40266.0587.5928-.0271s.36759-.2784.39677-.53764c.0444-.34773-.45754-.4098-.49179-.0608-.007.0645-.0325.0918-.10089.12266-.0684.0309-.17338.0347-.23892.0116-.14175-.0499-.23018-.15706-.27844-.30474-.0483-.14769-.0426-.33016.0177-.47533.18569-.44721.7414-.59002 1.15687-.37683.56595.29041.73878 1.06543.44857 1.64222-.36974.73492-1.29647.95286-1.98251.55683-.83761-.4835-1.08091-1.64848-.61306-2.50692.55443-1.01733 1.85494-1.30832 2.8094-.7245.7342.44908 1.16253 1.33232 1.11115 2.22159-.024.34875.47822.38053.49451.0313.0625-1.08174-.45128-2.14723-1.35547-2.70028-.40731-.24914-.86445-.37015-1.31743-.36974z"></path>
                            <title>Control - A champion's ability to disable or disrupt enemies.</title>
                        </g>

                        <g className="combat-section stat-mobility">
                            <path className="combat-hover" fillOpacity="0" d="M30.621962 38.061337a5.820833 5.820833 0 0 1-5.16968-3.75982l-20.072706 6.52202 5.008485 15.41453c.5668 1.74407 2.19201 2.92496 4.02587 2.92522l16.20848.00031z"></path>
                            <g className="combat-stat">
                                <path className={`${playstyle?.mobility >= 1 ? 'combat-bar' : 'transparent'}`} d="M25.091355 34.836355l-3.02824.983403c1.36316 3.37506 4.530846 5.67837 8.161777 5.934522v-3.18637c-2.25468-.23834-4.211327-1.660345-5.133537-3.731555z"></path>
                                <path className={`${playstyle?.mobility >= 2 ? 'combat-bar' : 'transparent'}`} d="M19.537688 36.64038l-3.010152.978234c2.154177 5.76753 7.546685 9.689476 13.697356 9.961666v-3.182752c-4.77741-.26034-8.959194-3.2956-10.687204-7.75715z"></path>
                                <path className={`${playstyle?.mobility >= 3 ? 'combat-bar' : 'transparent'}`} d="M14.001592 38.439236l-3.010152.978236C13.933416 47.57674 21.555375 53.1157 30.224375 53.39388v-3.179135c-7.29598-.27004-13.705317-4.92229-16.222783-11.77551z"></path>
                            </g>
                            <path className="combat-icon" d="M12.020094 52.565987v.52915h1.76895c.12378-.22809.23639-.43601.28708-.52915zm2.63786 0s-1.30092 2.38819-1.41409 2.63471c-.1172.065-.21304.16042-.27952.27893l-.35508.63311 1.07143.60102.14988-.26719c.0741.0755.14877.17291.22196.26824.009.0115.019.0213.0301.0298.0266.0404.0508.0759.0703.0991.14556.17285.6143.48485.81623.48485h1.81336v-.50264c0-.40027-.32552-.72562-.72574-.72562h-.11173c-.33869 0-.8276-.14525-.6811-.5052l1.14722-2.04541zm-2.63786 1.05828v.52915h1.19758c.0928-.17294.18414-.34312.28462-.52915zm0 1.05828v.52926h.5088c.079-.13392.18877-.24461.31056-.34056.0285-.0571.059-.1172.0961-.1887z"></path>
                            <title>Mobility - A champion's ability to move quickly around the map, blink or dash.</title>
                        </g>

                        <g className="combat-section stat-utility">
                            <path className="combat-hover" fillOpacity="0" d="M25.285012 33.799667a5.820833 5.820833 0 0 1 1.97829-6.07851L14.85769 10.646297l-13.112382 9.5267c-1.483565 1.07801-2.104438 2.98858-1.53799 4.73277l5.008395 15.41528z"></path>
                            <g className="combat-stat">
                                <path className={`${playstyle?.utility >= 1 ? 'combat-bar' : 'transparent'}`} d="M24.771994 24.96719c-2.78863 2.3394-4.000173 6.063427-3.121773 9.595797l3.03031-.984436c-.47006-2.21798.27781-4.51817 1.96267-6.03529z"></path>
                                <path className={`${playstyle?.utility >= 2 ? 'combat-bar' : 'transparent'}`} d="M21.350497 20.257916c-4.819562 3.83101-6.882814 10.171728-5.241023 16.105477l3.026688-.983402c-1.228706-4.62404.365477-9.53887 4.074687-12.56099z"></path>
                                <path className={`${playstyle?.utility >= 3 ? 'combat-bar' : 'transparent'}`} d="M17.929 15.54916c-6.850807 5.31934-9.763206 14.279836-7.348905 22.610506l3.02359-.98237c-1.99776-7.02234.445805-14.55556 6.185668-19.06757z"></path>
                            </g>
                            <path className="combat-icon" d="M2.512197 21.209537c0 1.78594 1.36071 2.72148 1.36071 2.72148v1.36071c-.25513 0-.68044.3401-.68044.3401v.34021h2.04115v-.34021s-.42529-.3401-.68044-.3401v-1.36071s1.36071-.93554 1.36071-2.72148z"></path>
                            <title>Utility - A champion's ability to grant beneficial effects to their allies or to provide vision.</title>
                        </g>

                        <g className="combat-section stat-center">
                            <path className="combat-hover" fill="#fff" fillOpacity="0" d="M36.177962 32.257647a5.291666 5.291667 0 0 1-5.29167 5.29167 5.291666 5.291667 0 0 1-5.29166-5.29167 5.291666 5.291667 0 0 1 5.29166-5.29166 5.291666 5.291667 0 0 1 5.29167 5.29166z"></path>
                            <path className="combat-middle" fill="#00202d" d="M34.590594 32.25765a3.7043 3.7043 0 0 1-3.7043 3.7043 3.7043 3.7043 0 0 1-3.704298-3.7043 3.7043 3.7043 0 0 1 3.704298-3.704297 3.7043 3.7043 0 0 1 3.7043 3.704298z"></path>
                            <title>Note: 1, 2, 3 are used to grade a champion's different playstyle </title>
                        </g>
                    </svg>
                </div>
            </div>
        </div>

    )
}

export default CombatBack;