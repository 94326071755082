import React, { createContext, useContext, useEffect, useState } from 'react';
import { ddChampListAPI, getCurrentVersion, setCurrentVersion, checkVersion } from '../config/RiotAPI';
import { regionAPI } from '../config/Region';

import { roleImages } from '../assets';

import DOMPurify from "dompurify";
import parse from "html-react-parser"; //alternative: react-html-parser not updated 

//Constants
import { uniqueRole } from '../config/Constants';

const ChampListContext = createContext(); //first create a context

export const useChampListContext = () => useContext(ChampListContext);
//To make it easy to consume the context in components, 
//we can create a custom hook that wraps the useContext hook and returns the context data.

const ChampListProvider = ({ children }) => {
  const [champList, setChampList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterRegion, setFilterRegion] = useState('');
  const [filterRole, setFilterRole] = useState('');

  const [selectedSplash, setSelectedSplash] = useState(0)

  //Here we are using (Set) which is simply an array but have distinct values therefore no duplicates.
  const uniqueRegion = [...new Set(champList.map((list) => list.region))];
  const roleKeys = Object.keys(roleImages);

  const sanitizeText = (dirtyText) => {
    const cleanText = DOMPurify.sanitize(dirtyText) //DOMPurify to avoid XXS
    return parse(cleanText);
  }

  const merge = (...arrays) => {
    console.log("Merging")
    //the merge function will merge two arrays (riot API (no region property)) with RegionAPI this can be 
    // scrapped if we intend not to use the Data Dragon as the main API source.
    const merged = {};
    arrays.forEach(data => {
      data.forEach(o => Object.assign(merged[o.name] ??= {}, o))
    }
    );

    return Object.values(merged);
  }

  const getChampList = async () => {
    try {
      console.log(`Fetching... Champion List ver:${getCurrentVersion()}`)
      const response = await fetch(`${ddChampListAPI()}`)
      if (!response.ok) {
        throw Error(`${response.status} - ${response.statusText}`)
      }

      const data = await response.json(); //.then(console.log(response))
      const aw = data.data

      const mainObj = Object.values(aw)

      const mergedAPI = mergeChampList(mainObj, regionAPI);
      setChampList(mergedAPI); // Merge and set the list here

      setIsLoading(false);
      setErrorMessage(null);

      /* setChampList(mainObj)  */
    }
    catch (error) {
      console.error('Error fetching champ list:', error);
    }
  }

  const mergeChampList = (champList, regionAPI) => {
    try {
      const mergedAPI = merge(champList, regionAPI);
      return mergedAPI;
      /*             console.log(champList)
                console.log(mergedAPI) */
      //console.log(Object.keys(aw))
    }
    catch {
      setErrorMessage('Error while fetching version update');
      setIsLoading(false);
      return champList;
    }
  }

  const fetchList = async () => {
    const newVersion = await checkVersion(); //need to run first 
    setCurrentVersion(newVersion)
    getChampList() //getChampList('')

  }

  useEffect(() => {
    fetchList();
  }, []);

  //Creating the Provider component 
  return (
    <ChampListContext.Provider value={{
      champList, setChampList,
      errorMessage, setErrorMessage,
      isLoading, setIsLoading,
      searchTerm, setSearchTerm,
      filterRegion, setFilterRegion,
      filterRole, setFilterRole,
      uniqueRegion, uniqueRole, roleKeys,
      sanitizeText,
      selectedSplash, setSelectedSplash
    }}>
      {children}
    </ChampListContext.Provider>
  )
}
export default ChampListProvider;